import React from 'react'
import Loading from './loading'

/**
 * 懒加载
 * @param {*} Comp 
 * @returns 
 */
const LazyLoad = (Comp) => {
    return (
        <React.Suspense fallback={<Loading />}>
            <Comp />
        </React.Suspense>
    )
}
export default LazyLoad
