import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import rootRouter from './router/index';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

const AppRoutes = () => useRoutes(rootRouter);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <div className="page-container" style={{ maxWidth: 768, margin: 'auto' }}>
        <ConfigProvider locale={zhCN}>
            <Router>
                <AppRoutes />
            </Router>
        </ConfigProvider>
    </div>,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
