import React from 'react'

const Loading = () => {
    return (
        <div className="flex-ac-jc flex-direction-col" style={{ height: '100vh' }}>
            加载中...
        </div>
    )
}
export default Loading
