import { lazy } from 'react';
import lazyLoad from '../components/lazy-load';

const Home = lazy(() => import(`../pages/index`));
const MemberDetail = lazy(() => import(`../pages/member-detail/index`));
const RecordsDetail = lazy(() => import(`../pages/member-detail/records-detail`));
const rootRouter = [
    {
        path: '/',
        name: '首页',
        key: 'root',
        children: [
            {
                index: true,
                name: '会员详情',
                key: 'member-detail',
                element: lazyLoad(MemberDetail),
            },
            {
                index: false,
                path: 'records-detail',
                name: '消费记录详情',
                key: 'records-detail',
                element: lazyLoad(RecordsDetail),
            },
        ],
    },
];
export default rootRouter;
